import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useAuth} from '../../../../../app/modules/auth'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const menu: {[key: string]: string} = {
  Sprints: 'gestao-de-sprints',
  Cronogramas: 'cronogramas',
  'Time Tracking': 'timetracking',
  Clientes: 'gestao-de-clientes',
  Contratos: 'gestao-de-contratos',
  Posts: 'wiki',
  Rascunhos: 'wiki',
  Categorias: 'wiki',
  Tags: 'wiki',
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {currentUser} = useAuth()
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  console.log(title)

  return (
    <div className='menu-item'>
      {currentUser?.instance?.plan?.modules?.some(
        (module) =>
          ['Dashboard', 'Equipes', 'Usuários', 'Relatórios', 'Projetos'].includes(title) ||
          (module.slug_name == menu[title] && module.pivot.active)
      ) ? (
        <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title'>{title}</span>
        </Link>
      ) : (
        <OverlayTrigger
          placement='top'
          delay={{show: 50, hide: 100}}
          overlay={
            <Tooltip>
              Este recurso está disponível apenas em planos superiores. Atualize seu plano para
              acessá-lo.
            </Tooltip>
          }
        >
          <div className='menu-link without-sub disabled' style={{cursor: 'not-allowed'}}>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                {' '}
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            <span className='menu-title'>{title}</span>
            <i className='fa fa-lock'></i>
          </div>
        </OverlayTrigger>
      )}

      {children}
    </div>
  )
}

export {SidebarMenuItem}
